import React, { useState, useEffect } from "react";
import useStyles from "../style";

import { useHistory, Link } from "react-router-dom";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

import useCancellablePromise from "../../../api/cancelRequest";

const LoanSanctionDetails = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const [loanDetails, setLoanDetails] = useState();
  const [loanDisbursementDetails, setLoanDisbursementDetails] = useState();
  const [bankName, setBankName] = useState();

  const [isLoading, setIsLoading] = useState(false);
  // HOOKS
  const { cancellablePromise } = useCancellablePromise();

  const getLoanEligibilityDetails = () => {
    setIsLoading(true);
    try {
      //const data = await cancellablePromise(searchForBankers(""));
      const data = {
        context: {
          domain: "ONDC:FIS12",
          location: {
            country: {
              code: "IND",
            },
            city: {
              code: "*",
            },
          },
          action: "on_select",
          version: "2.0.0",
          bap_id: "bap.credit.becknprotocol.io",
          bap_uri: "https://bap.credit.becknprotocol.io/",
          bpp_id: "bpp.credit.becknprotocol.org",
          bpp_uri: "https://bpp.credit.becknprotocol.org",
          transaction_id: "a9aaecca-10b7-4d19-b640-b047a7c62195",
          message_id: "c8e3968c-cd78-4e46-aa34-0d541e46bd73",
          timestamp: "2023-05-25T05:23:03.443Z",
          ttl: "P30M",
        },
        message: {
          order: {
            provider: {
              id: "PROVIDER_ID",
              descriptor: {
                images: [
                  {
                    url: "https://www.icicibank.com/content/dam/icicibank/india/assets/images/header/logo.png",
                    size_type: "sm",
                  },
                ],
                name: "ICICI Bank",
                short_desc: "ICICI Bank Ltd",
                long_desc: "ICICI Bank Ltd, India.",
              },
              tags: [
                {
                  descriptor: {
                    code: "CONTACT_INFO",
                    name: "Contact Info",
                  },
                  list: [
                    {
                      descriptor: {
                        code: "GRO_NAME",
                        name: "Gro name",
                      },
                      value: "ICICI",
                    },
                    {
                      descriptor: {
                        code: "GRO_EMAIL",
                        name: "Gro email",
                      },
                      value: "lifeline@iciciprulife.com",
                    },
                    {
                      descriptor: {
                        code: "GRO_CONTACT_NUMBER",
                        name: "Gro contact number",
                      },
                      value: "1860 266 7766",
                    },
                    {
                      descriptor: {
                        code: "GRO_DESIGNATION",
                        name: "Gro Designation",
                      },
                      value: "Nodal Grievance Redressal Officer",
                    },
                    {
                      descriptor: {
                        code: "GRO_ADDRESS",
                        name: "Gro Address",
                      },
                      value:
                        "One Indiabulls centre, Tower 1, 18th Floor Jupiter mill compound 841, Senapati Bapat Marg, Elphinstone Road, Mumbai 400013",
                    },
                    {
                      descriptor: {
                        code: "CUSTOMER_SUPPORT_LINK",
                        name: "Customer support link",
                      },
                      value:
                        "https://buy.iciciprulife.com/buy/GrievanceRedStep.htm?execution=e1s1",
                    },
                    {
                      descriptor: {
                        code: "CUSTOMER_SUPPORT_CONTACT_NUMBER",
                        name: "Customer support contact number",
                      },
                      value: "1800 1080",
                    },
                    {
                      descriptor: {
                        code: "CUSTOMER_SUPPORT_EMAIL",
                        name: "Customer support email",
                      },
                      value: "customer.care@icicibank.com",
                    },
                  ],
                },
                {
                  descriptor: {
                    code: "LSP_INFO",
                    name: "Lsp Info",
                  },
                  list: [
                    {
                      descriptor: {
                        code: "LSP_NAME",
                        name: "Lsp name",
                      },
                      value: "ICICI_LSP",
                    },
                    {
                      descriptor: {
                        code: "LSP_EMAIL",
                        name: "Lsp email",
                      },
                      value: "lsp@iciciprulife.com",
                    },
                    {
                      descriptor: {
                        code: "LSP_CONTACT_NUMBER",
                        name: "Lsp contact number",
                      },
                      value: "1860 266 7766",
                    },
                    {
                      descriptor: {
                        code: "LSP_ADDRESS",
                        name: "Lsp Address",
                      },
                      value:
                        "One Indiabulls centre, Tower 1, 18th Floor Jupiter mill compound 841, Senapati Bapat Marg, Elphinstone Road, Mumbai 400013",
                    },
                  ],
                },
              ],
            },
            items: [
              {
                id: "ITEM_ID_PERSONAL_LOAN",
                descriptor: {
                  code: "PERSONAL_LOAN",
                  name: "Personal Loan",
                },
                price: {
                  currency: "INR",
                  value: "441800",
                },
                tags: [
                  {
                    descriptor: {
                      code: "LOAN_INFO",
                      name: "Loan Information",
                    },
                    list: [
                      {
                        descriptor: {
                          code: "INTEREST_RATE",
                          name: "Interest Rate",
                          short_desc: "Loans starting from 12% (p.a)",
                        },
                        value: "12%",
                      },
                      {
                        descriptor: {
                          code: "TERM",
                          name: "Loan Term",
                          short_desc: "Loans Duration 5 months",
                        },
                        value: "5 months",
                      },
                      {
                        descriptor: {
                          code: "INTEREST_RATE_TYPE",
                          name: "Interest rate type",
                          short_desc: "Fixed Interest type",
                        },
                        value: "FIXED",
                      },
                      {
                        descriptor: {
                          code: "APPLICATION_FEE",
                          name: "Application Fees",
                        },
                        value: "1000 INR",
                      },
                      {
                        descriptor: {
                          code: "FORECLOSURE_FEE",
                          name: "Foreclosure Penalty",
                          short_desc: "Loan Foreclosure Penalty",
                        },
                        value: "0.5%",
                      },
                      {
                        descriptor: {
                          code: "INTEREST_RATE_CONVERSION_CHARGE",
                          name: "interest rate conversion charges",
                        },
                        value: "1000 INR",
                      },
                      {
                        descriptor: {
                          code: "DELAY_PENALTY_FEE",
                          name: "Delayed payments penalty",
                          short_desc: "Delayed payments penalty",
                        },
                        value: "5%",
                      },
                      {
                        descriptor: {
                          code: "OTHER_PENALTY_FEE",
                          name: "Other Penal Charges",
                          short_desc: "Other Penal Charges",
                        },
                        value: "1%",
                      },
                      {
                        descriptor: {
                          code: "ANNUAL_PERCENTAGE_RATE",
                          name: "Annual Percentage Rate",
                          short_desc:
                            "Effective annualized interest rate in percentage computed on net disbursed amount using IRR approach and reducing balance method",
                        },
                        value: "5%",
                      },
                      {
                        descriptor: {
                          code: "REPAYMENT_FREQUENCY",
                          name: "Repayment Frequency",
                          short_desc: "Repayment Frequency by borrower",
                        },
                        value: "MONTHLY",
                      },
                      {
                        descriptor: {
                          code: "NUMBER_OF_INSTALLMENTS_OF_REPAYMENT",
                          name: "Number of installments of repayment",
                          short_desc:
                            "Number of installments borrower has to make to payback the loan",
                        },
                        value: "45",
                      },
                      {
                        descriptor: {
                          code: "TNC_LINK",
                          name: "Terms & Conditions",
                          short_desc: "Terms and Conditions",
                        },
                        value: "https://icicibank.com/loan/tnc.html",
                      },
                      {
                        descriptor: {
                          code: "COOL_OFF_PERIOD",
                          name: "cool off period",
                          short_desc:
                            "Cooling off/look-up period during which borrower shall not be\tTAGS m jayega charged any penalty",
                        },
                        value: "PT30D",
                      },
                      {
                        descriptor: {
                          code: "INSTALLMENT_AMOUNT",
                          name: "Installment amount",
                          short_desc: "Each installment amount of repayment",
                        },
                        value: "PT30D",
                      },
                    ],
                    display: true,
                  },
                ],
                xinput: {
                  head: {
                    descriptor: {
                      name: "Customer Information",
                    },
                    index: {
                      min: 0,
                      cur: 0,
                      max: 1,
                    },
                    headings: ["Set Loan Amount", "Know your Customer"],
                  },
                  form: {
                    id: "FO2",
                    mime_type: "text/html",
                    url: "https://https://bpp.credit.becknprotocol.org/loans-details/xinput?formid=FO2",
                    resubmit: false,
                    multiple_sumbissions: false,
                  },
                  required: true,
                },
              },
            ],
            quote: {
              id: "LOAN_LEAD_ID_OR_SIMILAR",
              price: {
                currency: "INR",
                value: "442300",
              },
              breakup: [
                {
                  title: "PRINCIPAL",
                  price: {
                    value: "400000",
                    currency: "INR",
                  },
                },
                {
                  title: "INTEREST",
                  price: {
                    value: "40000",
                    currency: "INR",
                  },
                },
                {
                  title: "PROCESSING_FEE",
                  price: {
                    value: "1800",
                    currency: "INR",
                  },
                },
                {
                  title: "OTHER_UPFRONT_CHARGES",
                  price: {
                    value: "0",
                    currency: "INR",
                  },
                },
                {
                  title: "INSURANCE_CHARGES",
                  price: {
                    value: "500",
                    currency: "INR",
                  },
                },
                {
                  title: "NET_DISBURSED_AMOUNT",
                  price: {
                    value: "397700",
                    currency: "INR",
                  },
                },
                {
                  title: "OTHER_CHARGES",
                  price: {
                    value: "0",
                    currency: "INR",
                  },
                },
              ],
              ttl: "PT15D",
            },
          },
        },
      };
      //const data = JSON.parse(sessionStorage.getItem("loanSanctionDetails"));
      // const formUrl =
      //   data?.message.catalog.providers[0].items[0].xinput.form.url;
      setBankName(data.message.order.provider.descriptor.short_desc);
      setLoanDetails(data.message.order.items[0]);
      setLoanDisbursementDetails(data.message.order.quote);
      console.log("LoanDetails==", data.message.order.items[0].tags[0]);
      console.log("loanDisbursementDetails==", data.message.order.quote);
      console.log(
        "bank name==",
        data.message.order.provider.descriptor.short_desc
      );
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };

  const makeLowecase = (text) => {
    return (text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()).replace(
      /_/g,
      " "
    );
  };

  useEffect(() => {
    //const data = sessionStorage.getItem("loanEligibilityDetails");
    getLoanEligibilityDetails();
  }, []);

  return (
    <React.Fragment>
      {bankName && (
        <div>
          <Typography component="div" mt={2} ml={4}>
            <Link
              to="/application/home"
              style={{
                textDecoration: "none",
                color: "#196aab",
                fontWeight: "bold",
              }}
              className={classes.navigation_link}
            >
              &#60;- Back to Home
            </Link>
          </Typography>
          <Typography
            component="div"
            variant="h3"
            my={2}
            align="center"
            color="#196aab"
          >
            {bankName} - Your Loan Sanction Details
          </Typography>
          <Grid
            container
            spacing={0}
            className={classes.productItemContainerList}
            sx={{ width: "auto", height: "auto" }}
            mx={3}
            mb={5}
          >
            <Grid
              item
              xs={12}
              className={classes.bankInformationList}
              sx={{
                borderRadius: "12px",
              }}
            >
              <Typography
                component="div"
                variant="h5"
                className={classes.headerTitle}
              >
                Loan Amount Sanctioned
              </Typography>

              {loanDisbursementDetails.breakup.map((item, ind) => {
                return (
                  <Box style={{ display: "inline-block" }} key={ind}>
                    <Typography
                      variant="body"
                      style={{ display: "block", marginRight: "30px" }}
                      className={classes.descriptionItemList}
                    >
                      {makeLowecase(item.title)}
                    </Typography>
                    <Typography
                      variant="body"
                      style={{
                        display: "block",
                        //textAlign: "center",
                        fontWeight: "bold",
                      }}
                      className={classes.descriptionItemList}
                    >
                      {item.price.value}
                    </Typography>
                  </Box>
                );
              })}
              <div className={classes.footerActions}>
                <Button
                  className={classes.applyBtn}
                  variant="outlined"
                  onClick={(e) => {
                    history.push(`/application/customerBankDetails`);
                  }}
                >
                  Click to Proceed
                </Button>
              </div>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={0}
            className={classes.productItemContainerList}
            sx={{ width: "auto", height: "auto" }}
            mx={3}
            mb={10}
          >
            <Grid
              item
              xs={12}
              sx={{
                borderRadius: "12px",
              }}
              className={classes.productDetailsTypo}
            >
              <Typography
                component="div"
                variant="h5"
                className={classes.headerTitle}
              >
                Loan Interest & Payment deatils
              </Typography>

              {loanDetails.tags[0].list.map((item, ind) => {
                if (
                  item.descriptor.code !== "INSTALLMENT_AMOUNT" &&
                  item.descriptor.code !== "COOL_OFF_PERIOD"
                ) {
                  return (
                    <Box
                      style={{ display: "inline-block", marginBottom: "20px" }}
                      key={ind}
                    >
                      <Typography
                        variant="body"
                        style={{ display: "block", marginRight: "30px" }}
                        className={classes.descriptionItemList}
                      >
                        {item.descriptor.name}
                      </Typography>
                      <Typography
                        pl={1}
                        variant="body"
                        style={{
                          display: "block",
                          fontWeight: "bold",
                        }}
                        className={classes.descriptionItemList}
                      >
                        {item.descriptor.code === "TNC_LINK" ? (
                          <a href={item.value} rel="noreferrer" target="_blank">
                            Click here
                          </a>
                        ) : (
                          item.value
                        )}
                      </Typography>
                    </Box>
                  );
                }
              })}
            </Grid>
          </Grid>
        </div>
      )}
    </React.Fragment>
  );
};
export default LoanSanctionDetails;
