import React from "react";
import useStyles from "../style";
import Box from "@mui/material/Box";
import { useHistory } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import no_image_found from "../../../assets/images/no_image_found.png";

const LoanSearch = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const { selectedBankInfo } = props;
  sessionStorage.setItem(
    "selectedBankDetails",
    JSON.stringify(selectedBankInfo)
  );

  const selectedBankInfoGet = JSON.parse(
    sessionStorage.getItem("selectedBankDetails")
  );
  console.log("selectedBankInfoGet==", selectedBankInfoGet);
  const bankName =
    selectedBankInfo?.message.catalog.providers[0].descriptor.short_desc;
  const selectedFormUrl =
    selectedBankInfo?.message.catalog.providers[0].items[0].xinput.form.url;
  const selectedFormId =
    selectedBankInfo?.message.catalog.providers[0].items[0].xinput.form.id;
  console.log("selectedFormUrl===", selectedFormUrl);
  console.log("selectedFormId===", selectedFormId);
  const contactInfoList =
    selectedBankInfo?.message.catalog.providers[0].tags[0].list;
  const bankLoanInfo =
    selectedBankInfo?.message.catalog.providers[0].items[0].tags[0].list;
  const imageUrl =
    selectedBankInfo?.message.catalog.providers[0].descriptor.images[0].url;

  return (
    <Grid
      container
      spacing={0}
      className={classes.productItemContainerList}
      sx={{ width: "auto", height: "auto" }}
      mx={3}
      mb={10}
    >
      <Grid item xs={12} sm={12} md={2} className={classes.productCardList}>
        <img
          className={classes.loanProductImage}
          src={imageUrl ? imageUrl : no_image_found}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={10}
        className={classes.productDetailsTypo}
        sx={{ borderTopRightRadius: "12px", borderBottomRightRadius: "12px" }}
      >
        <Typography
          component="div"
          variant="h5"
          className={classes.headerTitle}
        >
          {/* {bankName} */}
          Find the Best Personal Loan Offers
        </Typography>

        {bankLoanInfo.map((item, ind) => {
          return (
            <Box
              style={{ display: "inline-block", marginTop: "20px" }}
              key={ind}
            >
              <Typography
                variant="body"
                style={{ display: "block", marginRight: "30px" }}
                className={classes.descriptionItemList}
              >
                {item.descriptor.name}
              </Typography>
              <Typography
                pl={1}
                variant="body"
                style={{
                  display: "block",
                  fontWeight: "bold",
                }}
                className={classes.descriptionItemList}
              >
                {item.value}
              </Typography>
            </Box>
          );
        })}

        <Typography
          component="div"
          variant="h5"
          className={classes.headerTitle}
          mt={3}
        >
          Contact Information
        </Typography>

        {contactInfoList.map((item, ind) => {
          return (
            <Box style={{ display: "block" }} key={ind}>
              <Typography
                variant="body"
                className={classes.descriptionItemList}
              >
                {item.descriptor.name} :
              </Typography>
              <Typography
                pl={1}
                variant="body"
                className={classes.descriptionItemList}
                style={{
                  fontWeight: "bold",
                }}
              >
                {item.value}
              </Typography>
            </Box>
          );
        })}
        <div className={classes.footerActions} style={{ top: "20px" }}>
          <Button
            className={classes.applyBtn}
            variant="outlined"
            onClick={(e) => {
              history.push(`/application/customerDetails`);
            }}
          >
            Check Eligibility
          </Button>
        </div>
      </Grid>
    </Grid>
  );
};
export default LoanSearch;
