import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  loanProductImage: {
    margin: "auto !important",
    maxHeight: "95%",
    maxWidth: "95%",
    borderRadius: "5px",
  },
  divider: {
    height: "1px",
    backgroundColor: "#EDEDED !important",
    marginTop: "10px",
  },
  colorBlue: {
    color: "#196aab",
  },

  productItemContainerList: {
    border: `1px solid #EDEDED`,
    borderRadius: "12px !important",
  },
  productCardList: {
    boxShadow: "none !important",
    background: "linear-gradient(180deg, #F9F2E2 0%, #D9C9A8 100%) !important",
    display: "flex",
    height: "auto",
    minHeight: "360px !important",
    borderRadius: "12px 0px 0px 12px !important",
  },
  productDetailsTypo: {
    padding: "20px 16px 26px 16px",
    position: "relative",
    background: "linear-gradient(180deg, #DCE6F9 0%, #ADCDFE 100%) !important",
  },
  bankInformationList: {
    padding: "20px 16px 26px 16px",
    position: "relative",
    background: "linear-gradient(180deg, #F9F2E2 0%, #D9C9A8 100%) !important",
  },
  headerTitle: {
    fontWeight: "600 !important",
    marginBottom: "10px !important",
  },
  descriptionItemList: {
    marginTop: "3px !important",
    color: "#505050 !important",
    display: "inline-block",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: "13px !important",
  },
  footerActions: {
    position: "absolute",
    bottom: "16px",
    width: "calc(100% - 32px) !important",
  },
  applyBtn: {
    float: "right",
    marginLeft: "8px !important",
    width: "195px",
  },
  backToHome: {
    textDecoration: "none",
    color: "#196aab",
    fontWeight: "bold",
  },
  brandImage: {
    height: 50,
    width: 200,
  },
  // "& . MuiTablePagination-toolbar p": {
  //   marginTop: "5px",
  // },
});

export default useStyles;
