import React, { useState, useEffect, Fragment } from "react";
import { buttonTypes } from "../../shared/button/utils";
import Button from "../../shared/button/button";
import { Link, useHistory } from "react-router-dom";
import styles from "../../../styles/auth/auth.module.scss";
import useStyles from "../style";
import { Typography } from "@mui/material";
import Grid from "@mui/system/Unstable_Grid";
import { statusAPICall, initAPICall } from "../../../api/personal.loan.api";
import kyc from "../../../assets/images/kyc.png";

function KycForm() {
  const history = useHistory();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [showKyCButton, setShowKyCButton] = useState(false);
  const [selectedBankDetails, setSelectedBankDetails] = useState();

  const [kycUrl, setKycUrl] = useState();

  useEffect(() => {
    const selectedBankInfo = JSON.parse(
      sessionStorage.getItem("loanAmountDetails")
    );
    setSelectedBankDetails(selectedBankInfo);
    setKycUrl(selectedBankInfo[0].message.order.items[0].xinput.form.url);
  }, []);

  const makeKycInitAPICall = async (kycData) => {
    try {
      const data = await initAPICall(kycData);
      sessionStorage.setItem("kycSubmitDetails", JSON.stringify(data[0]));
      history.push("/application/customerBankDetails");
    } catch (err) {
      console.log("error in makeKycInitAPICall API");
    } finally {
    }
  };

  const checkKycDetails = async () => {
    setIsLoading(true);
    try {
      const data = await statusAPICall();
      makeKycInitAPICall(data[0]);
    } catch (err) {
      console.log("error in checkKycDetails API");
    } finally {
    }
  };

  const navigateToKYC = async () => {
    setShowKyCButton(true);
    window.open(kycUrl);
  };

  return (
    <div className={styles.auth_form}>
      <Typography component="div" my={3} ml={3}>
        <Link to="/application/home" className={classes.backToHome}>
          &#60; Back to Home
        </Link>
      </Typography>
      <Grid
        container
        spacing={0}
        sx={{ width: "auto", height: "auto" }}
        mx={3}
        mb={5}
      >
        <Grid
          item
          xs={4}
          className={classes.bankInformationList}
          sx={{
            borderTopLeftRadius: "12px",
            borderBottomLeftRadius: "12px",
          }}
        >
          <Typography component="div" my={3} ml={3}>
            <img src={kyc} height="200px" />
          </Typography>
        </Grid>
        <Grid
          item
          xs={7}
          className={classes.bankInformationList}
          sx={{
            borderTopRightRadius: "12px",
            borderBottomRightRadius: "12px",
          }}
        >
          <Typography component="div" variant="h3" my={2} color="#196aab">
            KYC Information
          </Typography>
          {showKyCButton ? (
            <div className="pb-4">
              <Typography component="div" my={2} color="#196aab">
                Thank you for completing KYC and please click{" "}
                <span style={{ fontWeight: "bold" }}> Continue</span> to proceed
                further.
              </Typography>
              <Button
                isloading={isLoading ? 1 : 0}
                disabled={isLoading}
                button_type={buttonTypes.primary}
                button_hover_type={buttonTypes.primary_hover}
                button_text="Continue"
                type="button"
                onClick={checkKycDetails}
              />
            </div>
          ) : (
            <Fragment>
              <Typography component="div" my={2} color="#196aab">
                <span style={{ fontWeight: "bold" }}>Note :</span> Please
                complete your KYC by clicking below link.
              </Typography>
              <Typography component="div" my={2}>
                <a
                  href="#"
                  rel="noreferrer"
                  target="_blank"
                  style={{ fontWeight: "bold" }}
                  onClick={navigateToKYC}
                >
                  Click to verify KYC
                </a>
              </Typography>
            </Fragment>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default KycForm;
