import React from "react";
//import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import ToastProvider from "./context/toastContext";
import ErrorBoundary from "./components/shared/error-boundary/errorBoundary";

createRoot(document.getElementById("root")).render(
  <ToastProvider>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </ToastProvider>
);

// createRoot(
//   <ToastProvider>
//     <ErrorBoundary>
//       <App />
//     </ErrorBoundary>
//   </ToastProvider>,
//   document.getElementById("root")
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
