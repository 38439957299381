import React, { useState, useEffect } from "react";
import useStyles from "../style";
import { buttonTypes } from "../../shared/button/utils";
import { useHistory, Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import formstyles from "../../../styles/auth/auth.module.scss";
import loanEligibility from "../../../assets/images/loan_Eligibility_new.jpg";
//import Button from "@mui/material/Button";

import Button from "../../shared/button/button";
import Box from "@mui/material/Box";

//import styles from "../../../styles/auth/auth.module.scss";
//import { makeStyles } from "@mui/styles";
import ModalComponent from "../../common/Modal";

const LoanEligibilityDetails = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const [loanDetails, setLoanDetails] = useState();
  const [loanDisbursementDetails, setLoanDisbursementDetails] = useState();
  const [bankName, setBankName] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [errorModal, setErrorModal] = useState(false);

  const [bankLogoUrl, setBankLogoUrl] = useState();
  const [errorMessage, setErrorMessage] = useState();

  const getLoanEligibilityDetails = () => {
    setIsLoading(true);
    try {
      const data = JSON.parse(sessionStorage.getItem("loanEligibilityDetails"));
      if (data[0]?.error) {
        setErrorMessage(data[0].error.message);
        setErrorModal(true);
      } else {
        setBankName(data[0].message.order.provider.descriptor.short_desc);
        setBankLogoUrl(data[0].message.order.provider.descriptor.images[0].url);
        setLoanDetails(data[0].message.order.items[0]);
        setLoanDisbursementDetails(data[0].message.order.quote);
      }
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };

  const makeLowecase = (text) => {
    return (text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()).replace(
      /_/g,
      " "
    );
  };

  // const ModalStyles = makeStyles((theme) => ({
  //   /** Changed modalStyle */
  //   modalStyle: { backgroundColor: "rgba(255, 0, 0, 0.5)" },
  //   /** Moved content styling to new class */
  //   contentStyle: {
  //     /** Also removed some invalid attributes **/
  //     color: "red",
  //     dispaly: "none",
  //   },
  // }));
  // const modalStyles = ModalStyles();

  useEffect(() => {
    getLoanEligibilityDetails();
    setTimeout(function () {
      document
        .getElementById("loanEligibilityDetailsContent")
        ?.scrollIntoView({ behavior: "instant", block: "start" });
    }, 200);
  }, []);

  return (
    <div
      id="loanEligibilityDetailsContent"
      style={{ scrollMarginTop: "100px" }}
    >
      {errorModal && (
        <ModalComponent
          open={errorModal}
          onClose={() => {
            setErrorModal(false);
            history.push("/application/home");
          }}
          title={"Error !"}
          //className={modalStyles.contentStyle}
        >
          <div className={classes.colorBlue}>{errorMessage}</div>

          <Typography component="div" my={2} align="center" color="#196aab">
            <Button
              button_type={buttonTypes.primary}
              button_text="Try with other Bank"
              button_hover_type={buttonTypes.primary_hover}
              onClick={(e) => {
                history.push(`/application/home`);
              }}
            >
              Try with other Bank
            </Button>
          </Typography>
        </ModalComponent>
      )}
      {bankName && (
        <div>
          <Typography component="div" mt={2} ml={4}>
            <Link to="/application/home" className={classes.backToHome}>
              &#60;- Back to Home
            </Link>
          </Typography>
          <div className={formstyles.auth_form}>
            <Grid
              container
              spacing={0}
              sx={{ width: "auto", height: "auto" }}
              mx={3}
              mb={5}
            >
              <Grid item xs={4}>
                <Typography component="div" my={3} ml={3}>
                  <img src={loanEligibility} height="200px" />
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography component="div" align="center" mt={2}>
                  <img
                    src={bankLogoUrl}
                    className={classes.brandImage}
                    alt="brand-image"
                  />
                </Typography>
                <Typography
                  component="div"
                  align="center"
                  variant="h3"
                  mt={2}
                  color="#196aab"
                >
                  {bankName}
                </Typography>
                <Typography
                  component="div"
                  align="center"
                  variant="h6"
                  mt={2}
                  color="#196aab"
                >
                  Your Loan Amount Eligibility Details
                </Typography>
                <div className="pb-4">
                  <Typography component="div" align="center" mt={4}>
                    <Button
                      button_type={buttonTypes.primary}
                      button_text="Cick to Apply"
                      button_hover_type={buttonTypes.primary_hover}
                      onClick={(e) => {
                        history.push(`/application/loanDetails`);
                      }}
                    >
                      Click to Apply
                    </Button>
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </div>
          {/* <Typography
            component="div"
            variant="h3"
            my={2}
            align="center"
            color="#196aab"
          >
            {bankName} - Your Loan Eligibility Details
          </Typography>
          <Typography component="div" align="center" mb={3}>
            <Button
              button_type={buttonTypes.primary}
              button_text="Cick to Apply"
              button_hover_type={buttonTypes.primary_hover}
              //className={classes.applyBtn}
              //variant="outlined"
              onClick={(e) => {
                history.push(`/application/loanDetails`);
              }}
            >
              Click to Apply
            </Button>
          </Typography> */}
          <Grid
            container
            spacing={0}
            className={classes.productItemContainerList}
            sx={{ width: "auto", height: "auto" }}
            mx={3}
            mb={5}
          >
            <Grid
              item
              xs={12}
              className={classes.bankInformationList}
              sx={{
                borderRadius: "12px",
              }}
            >
              <Typography
                component="div"
                variant="h5"
                className={classes.headerTitle}
              >
                Loan Amount Eligibility
              </Typography>

              {loanDisbursementDetails.breakup.map((item, ind) => {
                return (
                  <Box style={{ display: "inline-block" }} key={ind}>
                    <Typography
                      variant="body"
                      style={{ display: "block", marginRight: "30px" }}
                      className={classes.descriptionItemList}
                    >
                      {makeLowecase(item.title)}
                    </Typography>
                    <Typography
                      variant="body"
                      style={{
                        display: "block",
                        fontWeight: "bold",
                      }}
                      className={classes.descriptionItemList}
                    >
                      {item.price.value}
                    </Typography>
                  </Box>
                );
              })}
              {/* <div className={classes.footerActions}>
                <Button
                  className={classes.applyBtn}
                  variant="outlined"
                  onClick={(e) => {
                    history.push(`/application/loanDetails`);
                  }}
                >
                  Click to Apply
                </Button>
              </div> */}
            </Grid>
          </Grid>
          <Grid
            container
            spacing={0}
            className={classes.productItemContainerList}
            sx={{ width: "auto", height: "auto" }}
            mx={3}
            mb={10}
          >
            <Grid
              item
              xs={12}
              sx={{
                borderRadius: "12px",
              }}
              className={classes.productDetailsTypo}
            >
              <Typography
                component="div"
                variant="h5"
                className={classes.headerTitle}
              >
                Loan Interest & Payment deatils
              </Typography>

              {loanDetails.tags[0].list.map((item, ind) => {
                if (
                  item.descriptor.code !== "INSTALLMENT_AMOUNT" &&
                  item.descriptor.code !== "COOL_OFF_PERIOD"
                ) {
                  return (
                    <Box
                      style={{ display: "inline-block", marginBottom: "20px" }}
                      key={ind}
                    >
                      <Typography
                        variant="body"
                        style={{ display: "block", marginRight: "30px" }}
                        className={classes.descriptionItemList}
                      >
                        {item.descriptor.name}
                      </Typography>
                      <Typography
                        pl={1}
                        variant="body"
                        style={{
                          display: "block",
                          fontWeight: "bold",
                        }}
                        className={classes.descriptionItemList}
                      >
                        {item.descriptor.code === "TNC_LINK" ? (
                          <a href={item.value} rel="noreferrer" target="_blank">
                            Click here
                          </a>
                        ) : (
                          item.value
                        )}
                      </Typography>
                    </Box>
                  );
                }
              })}
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  );
};
export default LoanEligibilityDetails;
