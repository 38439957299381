import { deleteCall, getCall, postCall, putCall } from "../api/axios";
import { v4 as uuidv4 } from "uuid";

//get  Token

const getSelectedBankDetails = () => {
  return JSON.parse(sessionStorage.getItem("selectedBankDetails"));
};

export const getToken = () => {
  const payLoad = {
    context: {
      domain: "ONDC:FIS12",
      location: {
        country: {
          code: "IND",
        },
        city: {
          code: "*",
        },
      },
      transaction_id: "a9aaecca-10b7-4d19-b640-b047a7c62196",
      message_id: "bb579fb8-cb82-4824-be12-fcbc405b6608",
      action: "search",
      timestamp: new Date(),
      version: "2.0.0",
      bap_uri: "https://bap.credit.becknprotocol.io/",
      bap_id: "bap.credit.becknprotocol.io",
      ttl: "PT10M",
    },
    message: {
      intent: {
        category: {
          descriptor: {
            code: "PERSONAL_LOAN",
          },
        },
        payment: {
          collected_by: "BPP",
          tags: [
            {
              descriptor: {
                code: "BUYER_FINDER_FEES",
              },
              display: false,
              list: [
                {
                  descriptor: {
                    code: "BUYER_FINDER_FEES_TYPE",
                  },
                  value: "percent-annualized",
                },
                {
                  descriptor: {
                    code: "BUYER_FINDER_FEES_PERCENTAGE",
                  },
                  value: "1",
                },
              ],
            },
            {
              descriptor: {
                code: "SETTLEMENT_TERMS",
              },
              display: false,
              list: [
                {
                  descriptor: {
                    code: "DELAY_INTEREST",
                  },
                  value: "2.5",
                },
                {
                  descriptor: {
                    code: "STATIC_TERMS",
                  },
                  value:
                    "https://bap.credit.becknprotocol.io/personal-banking/loans/personal-loan",
                },
                {
                  descriptor: {
                    code: "OFFLINE_CONTRACT",
                  },
                  value: "true",
                },
              ],
            },
          ],
        },
      },
    },
  };
  return new Promise(async (resolve, reject) => {
    try {
      const data = await postCall(`/generate_signature`, payLoad);
      return resolve(data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const searchAPICall = () => {
  const payLoad = {
    context: {
      domain: "ONDC:FIS12",
      location: {
        country: {
          code: "IND",
        },
        city: {
          code: "*",
        },
      },
      transaction_id: uuidv4(),
      message_id: uuidv4(),
      action: "search",
      timestamp: new Date(),
      version: "2.0.0",
      bap_uri: "https://stagingondc.kynhost.com/",
      bap_id: "stagingondc.kynhost.com",
      ttl: "PT10M",
    },
    message: {
      intent: {
        category: {
          descriptor: {
            code: "PERSONAL_LOAN",
          },
        },
        payment: {
          collected_by: "BPP",
          tags: [
            {
              descriptor: {
                code: "BUYER_FINDER_FEES",
              },
              display: false,
              list: [
                {
                  descriptor: {
                    code: "BUYER_FINDER_FEES_TYPE",
                  },
                  value: "percent-annualized",
                },
                {
                  descriptor: {
                    code: "BUYER_FINDER_FEES_PERCENTAGE",
                  },
                  value: "1",
                },
              ],
            },
            {
              descriptor: {
                code: "SETTLEMENT_TERMS",
              },
              display: false,
              list: [
                {
                  descriptor: {
                    code: "DELAY_INTEREST",
                  },
                  value: "2.5",
                },
                {
                  descriptor: {
                    code: "STATIC_TERMS",
                  },
                  value:
                    "https://bap.credit.becknprotocol.io/personal-banking/loans/personal-loan",
                },
                {
                  descriptor: {
                    code: "OFFLINE_CONTRACT",
                  },
                  value: "true",
                },
              ],
            },
          ],
        },
      },
    },
  };
  return new Promise(async (resolve, reject) => {
    try {
      const data = await postCall(`/api/search`, payLoad);
      return resolve(data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const selectApproveAPICall = () => {
  const selectedBankInfo = getSelectedBankDetails();
  const selectedFormUrl = encodeURIComponent(
    selectedBankInfo?.message.catalog.providers[0].items[0].xinput.form.url
  );
  const payLoad = {
    context: {
      domain: "ONDC:FIS12",
      location: {
        country: {
          code: "IND",
        },
        city: {
          code: "*",
        },
      },
      transaction_id: sessionStorage.getItem("trasactionId"),
      message_id: uuidv4(),
      action: "select",
      timestamp: new Date(),
      version: "2.0.0",
      bap_uri: "https://stagingondc.kynhost.com/",
      bap_id: "stagingondc.kynhost.com",
      ttl: "PT10M",
      bpp_id: selectedBankInfo.context.bpp_id,
      bpp_uri: selectedBankInfo.context.bpp_uri,
    },
    message: {
      order: {
        provider: {
          id: selectedBankInfo?.message.catalog.providers[0].id,
        },
        items: [
          {
            id: selectedBankInfo?.message.catalog.providers[0].items[0].id,
            xinput: {
              form: {
                id: selectedBankInfo?.message.catalog.providers[0].items[0]
                  .xinput.form.id,
              },
              form_response: {
                status: "APPROVED",
                submission_id: sessionStorage.getItem("customerFormSubmitID"),
              },
            },
          },
        ],
      },
    },
  };
  return new Promise(async (resolve, reject) => {
    try {
      const data = await postCall(
        `/api/select/?url=${selectedBankInfo.context.bpp_uri}/select`,
        payLoad
      );
      return resolve(data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const selectAPICall = () => {
  const selectedBankInfo = getSelectedBankDetails();
  const selectedFormUrl = encodeURIComponent(
    selectedBankInfo?.message.catalog.providers[0].items[0].xinput.form.url
  );
  const payLoad = {
    context: {
      domain: "ONDC:FIS12",
      location: {
        country: {
          code: "IND",
        },
        city: {
          code: "*",
        },
      },
      transaction_id: sessionStorage.getItem("trasactionId"),
      message_id: uuidv4(),
      action: "select",
      timestamp: new Date(),
      version: "2.0.0",
      bap_uri: "https://stagingondc.kynhost.com/",
      bap_id: "stagingondc.kynhost.com",
      ttl: "PT10M",
      bpp_id: selectedBankInfo.context.bpp_id,
      bpp_uri: selectedBankInfo.context.bpp_uri,
    },
    message: {
      order: {
        provider: {
          id: selectedBankInfo?.message.catalog.providers[0].id,
        },
        items: [
          {
            id: selectedBankInfo?.message.catalog.providers[0].items[0].id,
            xinput: {
              form: {
                id: selectedBankInfo?.message.catalog.providers[0].items[0]
                  .xinput.form.id,
              },
              form_response: {
                status: "SUCCESS",
                submission_id: sessionStorage.getItem("customerFormSubmitID"),
              },
            },
          },
        ],
      },
    },
  };
  return new Promise(async (resolve, reject) => {
    try {
      const data = await postCall(
        `/api/select/?url=${selectedBankInfo.context.bpp_uri}/select`,
        payLoad
      );
      return resolve(data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const bankAdditionalDetailsInitAPICall = (kycData) => {
  const payLoad = {
    context: {
      domain: "ONDC:FIS12",
      location: {
        country: {
          code: "IND",
        },
        city: {
          code: "*",
        },
      },
      version: "2.0.0",
      action: "init",
      bap_uri: "https://stagingondc.kynhost.com/",
      bap_id: "stagingondc.kynhost.com",
      bpp_id: "bpp.credit.becknprotocol.org",
      bpp_id: kycData.context.bpp_id,
      bpp_uri: kycData.context.bpp_uri,
      transaction_id: sessionStorage.getItem("trasactionId"),
      message_id: uuidv4(),
      ttl: "PT10M",
      timestamp: new Date(),
    },
    message: {
      order: {
        provider: {
          id: kycData.message.order.provider.id,
        },
        items: [
          {
            id: kycData.message.order.items[0].id,
            xinput: {
              form: {
                id: kycData.message.order.items[0].xinput.form.id,
              },
              form_response: {
                status: "SUCCESS",
                submission_id:
                  kycData.message.order.items[0].xinput.form_response
                    .submission_id,
              },
            },
          },
        ],
        payments: [kycData.message.order.payments[0]],
      },
    },
  };

  return new Promise(async (resolve, reject) => {
    try {
      const data = await postCall(
        `/api/init/?url=${kycData.context.bpp_uri}/init`,
        payLoad
      );
      return resolve(data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const bankDetailsInitAPICall = (kycData) => {
  const payLoad = {
    context: {
      domain: "ONDC:FIS12",
      location: {
        country: {
          code: "IND",
        },
        city: {
          code: "*",
        },
      },
      version: "2.0.0",
      action: "init",
      bap_uri: "https://stagingondc.kynhost.com/",
      bap_id: "stagingondc.kynhost.com",
      bpp_id: "bpp.credit.becknprotocol.org",
      bpp_id: kycData.context.bpp_id,
      bpp_uri: kycData.context.bpp_uri,
      transaction_id: sessionStorage.getItem("trasactionId"),
      message_id: uuidv4(),
      ttl: "PT10M",
      timestamp: new Date(),
    },
    message: {
      order: {
        provider: {
          id: kycData.message.order.provider.id,
        },
        items: [
          {
            id: kycData.message.order.items[0].id,
            xinput: {
              form: {
                id: kycData.message.order.items[0].xinput.form.id,
              },
              form_response: {
                status: "SUCCESS",
                submission_id: sessionStorage.getItem("bankDetailsSubmitID"),
              },
            },
          },
        ],
        payments: [kycData.message.order.payments[0]],
        // payments: [
        //   {
        //     id: "PAYMENT_ID_PERSONAL_LOAN",
        //     collected_by: "BPP",
        //     type: "ON_ORDER",
        //     status: "NOT-PAID",
        //     params: {
        //       bank_code: "XXXXXXXX",
        //       bank_account_number: "xxxxxxxxxxxxxx",
        //       virtual_payment_address: "9988199772@okicic",
        //     },
        //     tags: [
        //       {
        //         descriptor: {
        //           code: "BUYER_FINDER_FEES",
        //         },
        //         display: false,
        //         list: [
        //           {
        //             descriptor: {
        //               code: "BUYER_FINDER_FEES_TYPE",
        //             },
        //             value: "percent-annualized",
        //           },
        //           {
        //             descriptor: {
        //               code: "BUYER_FINDER_FEES_PERCENTAGE",
        //             },
        //             value: "1",
        //           },
        //         ],
        //       },
        //       {
        //         descriptor: {
        //           code: "SETTLEMENT_TERMS",
        //         },
        //         display: false,
        //         list: [
        //           {
        //             descriptor: {
        //               code: "SETTLEMENT_AMOUNT",
        //             },
        //             value: "1159",
        //           },
        //           {
        //             descriptor: {
        //               code: "SETTLEMENT_TYPE",
        //             },
        //             value: "neft",
        //           },
        //           {
        //             descriptor: {
        //               code: "DELAY_INTEREST",
        //             },
        //             value: "5",
        //           },
        //           {
        //             descriptor: {
        //               code: "STATIC_TERMS",
        //             },
        //             value:
        //               "https://bap.credit.becknprotocol.io/personal-banking/loans/personal-loan",
        //           },
        //           {
        //             descriptor: {
        //               code: "OFFLINE_CONTRACT",
        //             },
        //             value: "true",
        //           },
        //         ],
        //       },
        //     ],
        //   },
        // ],
      },
    },
  };

  return new Promise(async (resolve, reject) => {
    try {
      const data = await postCall(
        `/api/init/?url=${kycData.context.bpp_uri}/init`,
        payLoad
      );
      return resolve(data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const initAPICall = (kycData) => {
  const payLoad = {
    context: {
      domain: "ONDC:FIS12",
      location: {
        country: {
          code: "IND",
        },
        city: {
          code: "*",
        },
      },
      version: "2.0.0",
      action: "init",
      bap_uri: "https://stagingondc.kynhost.com/",
      bap_id: "stagingondc.kynhost.com",
      bpp_id: kycData.context.bpp_id,
      bpp_uri: kycData.context.bpp_uri,
      transaction_id: sessionStorage.getItem("trasactionId"),
      message_id: uuidv4(),
      ttl: "PT10M",
      timestamp: new Date(),
    },
    message: {
      order: {
        provider: {
          id: kycData.message.order.provider.id,
        },
        items: [
          {
            id: kycData.message.order.items[0].id,
            xinput: {
              form: {
                id: kycData.message.order.items[0].xinput.form.id,
              },
              form_response: {
                status: "SUCCESS",
                submission_id:
                  kycData.message.order.items[0].xinput.form_response
                    .submission_id,
              },
            },
          },
        ],
        payments: [
          {
            collected_by: "BPP",
            type: "ON_ORDER",
            status: "NOT-PAID",
            params: {
              bank_code: "hdfc100097",
              bank_account_number: "1112307645612",
              virtual_payment_address: "9988199772@okicic",
            },
            tags: [
              {
                descriptor: {
                  code: "BUYER_FINDER_FEES",
                },
                display: false,
                list: [
                  {
                    descriptor: {
                      code: "BUYER_FINDER_FEES_TYPE",
                    },
                    value: "percent-annualized",
                  },
                  {
                    descriptor: {
                      code: "BUYER_FINDER_FEES_PERCENTAGE",
                    },
                    value: "1",
                  },
                ],
              },
              {
                descriptor: {
                  code: "SETTLEMENT_TERMS",
                },
                display: false,
                list: [
                  {
                    descriptor: {
                      code: "SETTLEMENT_AMOUNT",
                    },
                    value: "1159",
                  },
                  {
                    descriptor: {
                      code: "SETTLEMENT_TYPE",
                    },
                    value: "neft",
                  },
                  {
                    descriptor: {
                      code: "DELAY_INTEREST",
                    },
                    value: "5",
                  },
                  {
                    descriptor: {
                      code: "STATIC_TERMS",
                    },
                    value:
                      "https://bap.credit.becknprotocol.io/personal-banking/loans/personal-loan",
                  },
                  {
                    descriptor: {
                      code: "OFFLINE_CONTRACT",
                    },
                    value: "true",
                  },
                ],
              },
            ],
          },
        ],
      },
    },
  };

  return new Promise(async (resolve, reject) => {
    try {
      const data = await postCall(
        `/api/init/?url=${kycData.context.bpp_uri}/init`,
        payLoad
      );
      return resolve(data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const loanStatusAPICall = () => {
  const loanConfirmData = JSON.parse(
    sessionStorage.getItem("loanConfirmationDetails")
  );

  const payLoad = {
    context: {
      domain: "ONDC:FIS12",
      location: {
        country: {
          code: "IND",
        },
        city: {
          code: "*",
        },
      },
      action: "status",
      version: "2.0.0",
      bap_uri: "https://stagingondc.kynhost.com/",
      bap_id: "stagingondc.kynhost.com",
      bpp_id: "bpp.credit.becknprotocol.org",
      bpp_id: loanConfirmData.context.bpp_id,
      bpp_uri: loanConfirmData.context.bpp_uri,
      transaction_id: sessionStorage.getItem("trasactionId"),
      message_id: uuidv4(),
      ttl: "PT10M",
      timestamp: new Date(),
    },
    message: {
      ref_id: loanConfirmData.message.order.id,
    },
  };

  return new Promise(async (resolve, reject) => {
    try {
      const data = await postCall(
        `/api/status/?url=${loanConfirmData.context.bpp_uri}/status`,
        payLoad
      );
      return resolve(data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const loanUpdateAPICall = (loanConfirmData) => {
  const payLoad = {
    context: {
      domain: "ONDC:FIS12",
      location: {
        country: {
          code: "IND",
        },
        city: {
          code: "*",
        },
      },
      action: "update",
      version: "2.0.0",
      bap_uri: "https://stagingondc.kynhost.com/",
      bap_id: "stagingondc.kynhost.com",
      bpp_id: "bpp.credit.becknprotocol.org",
      bpp_id: loanConfirmData.context.bpp_id,
      bpp_uri: loanConfirmData.context.bpp_uri,
      transaction_id: sessionStorage.getItem("trasactionId"),
      message_id: uuidv4(),
      ttl: "PT10M",
      timestamp: new Date(),
    },
    message: {
      update_target: "fulfillment",
      order: {
        id: loanConfirmData.message.order.id,
        fulfillments: [
          {
            state: {
              descriptor: {
                code: "APPROVED",
              },
            },
          },
        ],
      },
    },
  };

  return new Promise(async (resolve, reject) => {
    try {
      const data = await postCall(
        `/api/update/?url=${loanConfirmData.context.bpp_uri}/update`,
        payLoad
      );
      return resolve(data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const loanAgreementDetailsConfirmAPICall = (kycData) => {
  const payLoad = {
    context: {
      domain: "ONDC:FIS12",
      location: {
        country: {
          code: "IND",
        },
        city: {
          code: "*",
        },
      },
      action: "confirm",
      version: "2.0.0",
      bap_uri: "https://stagingondc.kynhost.com/",
      bap_id: "stagingondc.kynhost.com",
      bpp_id: "bpp.credit.becknprotocol.org",
      bpp_id: kycData.context.bpp_id,
      bpp_uri: kycData.context.bpp_uri,
      transaction_id: sessionStorage.getItem("trasactionId"),
      message_id: uuidv4(),
      ttl: "PT10M",
      timestamp: new Date(),
    },
    message: {
      order: {
        provider: {
          id: kycData.message.order.provider.id,
        },
        items: [
          {
            id: kycData.message.order.items[0].id,
            xinput: {
              form: {
                id: kycData.message.order.items[0].xinput.form.id,
              },
              form_response: {
                status: "SUCCESS",
                submission_id:
                  kycData.message.order.items[0].xinput.form_response
                    .submission_id,
              },
            },
          },
        ],
        payments: [kycData.message.order.payments[0]],
      },
    },
  };

  return new Promise(async (resolve, reject) => {
    try {
      const data = await postCall(
        `/api/confirm/?url=${kycData.context.bpp_uri}/confirm`,
        payLoad
      );
      return resolve(data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const statusAPICall = () => {
  const transactionID = sessionStorage.getItem("trasactionId");
  return new Promise(async (resolve, reject) => {
    try {
      const data = await getCall(`/api/get/${transactionID}_on_status`);
      return resolve(data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const formSubmitAPICall = (formData, formUrl) => {
  const selectedBankInfo = getSelectedBankDetails();
  const selectedFormUrl = encodeURIComponent(formUrl);
  return new Promise(async (resolve, reject) => {
    try {
      const data = await postCall(
        `/api/applicationForm/?url=${selectedFormUrl}`,
        formData
      );
      return resolve(data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const loanAmountSelectAPICall = (formData) => {
  const selectedBankInfo = JSON.parse(
    sessionStorage.getItem("loanEligibilityDetails")
  );

  const selectedFormUrl = encodeURIComponent(
    selectedBankInfo[0]?.message.order.items[0].xinput.form.url
  );

  const payLoad = {
    context: {
      domain: "ONDC:FIS12",
      location: {
        country: {
          code: "IND",
        },
        city: {
          code: "*",
        },
      },
      transaction_id: sessionStorage.getItem("trasactionId"),
      message_id: uuidv4(),
      action: "select",
      timestamp: new Date(),
      version: "2.0.0",
      bap_uri: "https://stagingondc.kynhost.com/",
      bap_id: "stagingondc.kynhost.com",
      ttl: "PT10M",
      bpp_id: selectedBankInfo[0].context.bpp_id,
      bpp_uri: selectedBankInfo[0].context.bpp_uri,
    },
    message: {
      order: {
        provider: {
          id: selectedBankInfo[0]?.message.order.provider.id,
        },
        items: [
          {
            id: selectedBankInfo[0]?.message.order.items[0].id,
            xinput: {
              form: {
                id: selectedBankInfo[0]?.message.order.items[0].xinput.form.id,
              },
              form_response: {
                status: "SUCCESS",
                submission_id: sessionStorage.getItem("loanAmountFormSubmitID"),
              },
            },
          },
        ],
      },
    },
  };

  return new Promise(async (resolve, reject) => {
    try {
      const data = await postCall(
        `/api/select/?url=${selectedFormUrl}/select`,
        payLoad
      );
      return resolve(data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const bankDetailsAPICall = (formData) => {
  const selectedBankInfo = getSelectedBankDetails();
  const selectedFormUrl = encodeURIComponent(
    selectedBankInfo?.message.catalog.providers[0].items[0].xinput.form.url
  );
  return new Promise(async (resolve, reject) => {
    try {
      const data = await postCall(
        `/api/applicationForm/?url=${selectedFormUrl}`,
        formData
      );
      return resolve(data);
    } catch (err) {
      return reject(err);
    }
  });
};
