import React, { useState } from "react";
import useStyles from "../style";
import Input from "../../shared/input/input";
import ErrorMessage from "../../shared/error-message/errorMessage";

import Grid from "@mui/material/Grid";
import Button from "../../shared/button/button";
import { buttonTypes } from "../../shared/button/utils";

const LoanStausForm = (props) => {
  const classes = useStyles();

  const [submissionId, setSubmissionId] = useState();
  const [inlineError, setInlineError] = useState({
    submission_id_error: "",
  });

  const checkSubmissionId = () => {
    if (!submissionId) {
      setInlineError((error) => ({
        ...error,
        submission_id_error: "Please enter Submission ID",
      }));
      return false;
    }
    return true;
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Input
          id="submissionId"
          name="submissionId"
          type="text"
          placeholder="Enter Submission ID"
          label_name="Enter Submission ID"
          autoComplete="off"
          has_error={inlineError.submission_id_error}
          onChange={(event) => {
            setSubmissionId(event.target.value);
            setInlineError((inlineError) => ({
              ...inlineError,
              submission_id_error: "",
            }));
          }}
          onBlur={checkSubmissionId}
          required
        />
        {inlineError.submission_id_error && (
          <ErrorMessage>{inlineError.submission_id_error}</ErrorMessage>
        )}
      </Grid>
      <Grid item xs={12}>
        <div className="py-4 text-center">
          <Button
            isloading={0}
            button_type={buttonTypes.primary}
            button_hover_type={buttonTypes.primary_hover}
            button_text="Submit"
            type="submit"
          />
        </div>
      </Grid>
    </Grid>
  );
};
export default LoanStausForm;
